<template>
  <div class="component-dashboard-gauge-chart">
    <h3>{{ title }}</h3>
    <vue-gauge
      v-if="chartOptions !== null"
      :key="renderKey"
      :refid="'dashboard2-gauge-chart' + id"
      :options="chartOptions"
    />
    <p class="text-center gauge-value">{{ val }}</p>
    <!--<b-button variant="primary" class="btn mb-5 " style="border-radius: 24px;" @click="rnd">Randomize</b-button>-->
  </div>
</template>

<script>
import VueGauge from 'vue-gauge'
import _ from 'lodash'

export default {
  name: 'DashboardGaugeChart',
  components: { VueGauge },
  props: {
    id: {
      type: String,
      default: '-'
    },
    options: {
      type: Object,
      default: null
    },
    value: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: 'KPI'
    },
    width: {
      type: Number,
      default: 500
    },
    needleSpeed: {
      type: Number,
      default: 2000
    }
  },
  data () {
    return {
      renderKey: 1,
      val: null,
      rearrangedVal: null,
      rearrangedStructure: [],
      chartOptions: null,
      step: null
    }
  },
  created () {
    this.fillChart()
  },
  methods: {
    rnd () {
      this.val = Math.floor(Math.random() * 100)
    },
    defaultOptions () {
      return {
        arcDelimiters: [],
        arcColors: [],
        arcLabels: [],
        needleValue: 0,
        rangeLabel: [],
        chartWidth: this.width,
        hasNeddle: true,
        centralLabel: '',
        needleUpdateSpeed: this.needleSpeed
      }
    },
    fillChart () {
      this.chartOptions = this.defaultOptions()
      this.val = 0

      if (this.options) {
        this.rearrangedStructure = this.rearrangeStructure(this.options)

        this.chartOptions.rangeLabel = [
          this.rearrangedStructure.range.start.label,
          this.rearrangedStructure.range.end.label
        ]
        this.chartOptions.arcColors.push(this.rearrangedStructure.range.start.color)

        for (const i in this.rearrangedStructure.range) {
          if (isNaN(Number(i))) {
            continue
          }

          this.chartOptions.arcDelimiters.push(this.rearrangedStructure.range[i].value)
          this.chartOptions.arcColors.push(this.rearrangedStructure.range[i].color)
          this.chartOptions.arcLabels.push(this.rearrangedStructure.range[i].label)
        }

        this.chartOptions.arcDelimiters.push(this.rearrangedStructure.range.before_end.value)
        this.chartOptions.arcColors.push(this.rearrangedStructure.range.before_end.color)
        this.chartOptions.arcLabels.push(this.rearrangedStructure.range.before_end.label)

        this.val = this.rearrangeValue(this.value)
        this.chartOptions.needleValue = this.val
      }

      this.renderKey++
    },
    rearrangeStructure (structure) {
      const rearrangedStructure = _.cloneDeep(structure)

      const min = structure.range.start.value
      const max = structure.range.end.value
      this.step = (max - min) / 100

      for (const prop in rearrangedStructure.range) {
        if (['start', 'end'].indexOf(prop) !== -1) {
          continue
        }
        rearrangedStructure.range[prop].value = this.rearrangeValue(rearrangedStructure.range[prop].value, false)
      }

      rearrangedStructure.range.start.value = 0
      rearrangedStructure.range.end.value = 100

      return rearrangedStructure
    },
    rearrangeValue (value, isIncludeLimitsAllowed = true) {
      let val = Math.round((value - this.options.range.start.value) / this.step)
      if (val <= 0 && !isIncludeLimitsAllowed) {
        val = 1
      } else if (val >= 100 && !isIncludeLimitsAllowed) {
        val = 99
      }
      return val
    }
  },
  watch: {
    val () {
      this.chartOptions.needleValue = parseInt(this.val)
      this.renderKey++
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  }
}
</script>

<style lang="scss">
.component-dashboard-gauge-chart {
  h3 {
    text-align: center;
  }
  svg {
    display: block;
    margin: 0 auto;
  }
  .gauge-value {
    position: relative;
    top: -80px;
    font-family: monospace;;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
