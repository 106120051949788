<template>
  <b-container class="dashboard bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col cols="12" md="12" lg="8">
        <h1 v-if="currentUser">
          {{ $t("dashboard.welcome_user", { user: currentUser.firstname }) }}
        </h1>
        <p class="lead">
          {{ $t("dashboard.intro_lead") }}
        </p>
        <i18n :path="'dashboard.intro_p1'" tag="p" class="text-gray">
          <b-link slot="get-started-guide" href="https://forum.spirecta.se/t/kom-igang-ratt-och-latt-med-spirecta-borja-har/14" target="_blank" class="text-red">{{ $t("dashboard.intro_get_started_guide") }}</b-link>
          <b-link slot="spirecta-communityn" href="https://forum.spirecta.se/t/valkommen-till-spirecta-forumet-borja-har/7" target="_blank" class="text-red">{{ $t("dashboard.intro_community") }}</b-link>
        </i18n>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <template v-if="!isLoaded"><loader /></template>
        <template v-else>
          <b-row no-gutters v-for="(kpi, index) in kpiData" :key="index">
            <b-col>
              <dashboard-gauge-chart
                :id="index.toString()"
                :options="JSON.parse(kpi.json_settings)"
                :value="parseInt(kpi.kpi_value)"
                :title="kpi.kpi_type"
              />
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import DashboardGaugeChart from '@/components/charts/DashboardGaugeChart'

export default {
  name: 'Dashboard2',
  components: { Loader, DashboardGaugeChart },
  props: ['currentUser', 'currentCoa'],
  data () {
    return {
      kpiData: [],
      isLoaded: false
    }
  },
  mounted () {
    this.isLoaded = false
    this.loadKpiData().finally(() => {
      this.isLoaded = true
    })
  },
  methods: {
    async loadKpiData () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/kpi`)
          .then(response => {
            this.kpiData = response.data.data
            console.log(this.kpiData)
            resolve(response)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    }
  }
}
</script>
